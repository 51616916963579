import React from "react";

const Privacy = () => {
  return (
    <>
      <div className="privacy" id="company-privacy" style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${require('../assets/cover.jpg')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '50px'
      }}>
        <div className="section-intro">
          <h2 className="section-intro-title">Privacy Policy</h2>
        </div>
      </div>

      <div className="section" id="privacy-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="privacy-content">
                <h2>Your Privacy <em>Matters</em> to <em>Us</em></h2>
                <p>
                  At Quotus Software Solutions, we are committed to protecting your privacy and ensuring the security of your personal information. This privacy policy outlines our practices for collecting, using, and safeguarding your data.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="privacy-sections" style={{ marginBottom: '80px' }}> {/* Added margin bottom for spacing */}
        <div className="container">
          <div className="header">
            <h1 className="privacy-section-heading">Privacy Policy Details</h1>
            <div className="main-heading-domain-border-1 text-center">
              <div></div>
              <div></div>
            </div>
          </div>
          
          <div className="row1-container">
            <div className="box box-down cyan">
              <h2 className="inner-heading">Information Collection</h2>
              <p className="inn-heading">We collect and process your personal information with utmost care and transparency.</p>
            </div>

            <div className="box red">
              <h2 className="inner-heading">Data Protection</h2>
              <p className="inn-heading">Your data is protected using industry-standard security measures and encryption.</p>
            </div>

            <div className="box box-down blue">
              <h2 className="inner-heading">Your Rights</h2>
              <p className="inn-heading">You have full control over your personal data and how it's used.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="privacy-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="privacy-info-box">
                <h3>Information We Collect</h3>
                <ul>
                  <li>Personal identification information</li>
                  <li>Contact details</li>
                  <li>Technical usage data</li>
                  <li>Communication preferences</li>
                </ul>
              </div>
            </div>
            
            <div className="col-lg-6">
              <div className="privacy-info-box">
                <h3>How We Use Your Data</h3>
                <ul>
                  <li>Service improvement</li>
                  <li>Communication</li>
                  <li>Technical support</li>
                  <li>Legal compliance</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="contact-info">
                <h2>Contact Us About Privacy</h2>
                <p>If you have any questions about our privacy practices, please contact us:</p>
                <div className="contact-details">
                  <p>Email: <a href="mailto:info@quotus.co.in">info@quotus.co.in</a></p>
                  <br></br>
                  <p>Phone: +91 9777403555</p>
                  <br></br>
                  <p>Address: Arena-3, 3rd Floor, STPI ELITE Building, Gothapatna, Khordha, Odisha - 751003</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;